@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./assets/fonts/FuturaStd-Condensed.otf) format('otf');
}

@font-face {
  font-family: 'Futura C Bd';
  src: local('FuturaCBd'), url(./assets/fonts/NFUNCXBD.ttf) format('ttf');
}

@font-face {
  font-family: 'Futura C XBI';
  src: local('FuturaCXBi'), url(./assets/fonts/NFUNCXBI.ttf) format('ttf');
}

body {
  margin: 0;
  color: #ced2d5;
  font-family: 'Text Regular', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Futura C Bd', 'Futura C XBI', Futura, 'TradeGothic', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

h2 {
  font-family: Futura, 'Text Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h3 {
  font-family: 'Text Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

p {
  font-family: 'Text Regular', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  padding-left: 18px;
}

li {
  padding-left: 18px;
  margin-bottom: 8px;
}
